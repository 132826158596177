import "./Contact.css";

function Contact() {
    return(
        <div id="Contact">
            <h4>Contact me</h4>
            <form>
                <label for="name">Full name:</label>
                <input name="name" />
                <label for="email">Email:</label>
                <input name="email" />
                <label for="message">Message:</label>
                <textarea
                    name="message"
                    rows="3"
                />
                <button className="extra">Submit</button>
            </form>
        </div>
    )
}

export default Contact;
