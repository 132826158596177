import sweaterlayla from './images/sweaterlayla.png';
import dogtreat from './images/dogtreat.png';
import { useState, useEffect } from 'react';
import madlayla from './images/layla.png';
// import { Link } from 'react-router-dom';
import useMousePosition from './MousePosition';
import "./Layla.css"
import { motion } from "framer-motion";
import { animate } from 'framer-motion';
import laylasmiling from "./images/layla-smiling.png";

function LaylaTreat () {

    const [message, setMessage] = useState('Hey you! Give me that treat over there!')
    const [layla, setLayla] = useState(sweaterlayla);
    const [bonePosition, setBonePosition] = useState();
    const [clicked, setClicked] = useState(false);
    const [dropped, setDropped] = useState(false);
    const [success, setSuccess] = useState(false);
    const [saidNo, setSaidNo] = useState(false)

    const mousePosition = JSON.parse(JSON.stringify(useMousePosition()));
    // console.log("moise position: ", mousePosition.x)
    const sayNo = () => {
        setSaidNo(true)
        setLayla(madlayla)
        setMessage("Why not...")
    }

    const successMessage =() => {
        setLayla(laylasmiling)
        setMessage("Yummm..Thank you. Can I have another one?")
        setSuccess(true)
    }

    const handlePickup = (event) => {
        setMessage('yes! That treat! bring it over here!!')
        setClicked(true)
        setBonePosition(mousePosition)
    }
    const handleDrop = (event) => {
        // if treat has been dropped once already, target parameters change
        if (window.screen.width > 425) {
            console.log("window screen is bigger than 425")
            setBonePosition(mousePosition)
            if (dropped) {
                if (bonePosition.x >= 300 && bonePosition.x <=500 && bonePosition.y >= 400 && bonePosition.y <= 500) {
                    console.log("in target")
                    animate(
                        ".treat",
                        { opacity: 0 },
                        { duration: 0.5}
                    )
                    successMessage();
                } else {
                    animate(
                        ".treat",
                        {y: [mousePosition, 600]},
                        { type: "spring"},
                        {duration: 2}
                    )
                    setMessage('Can you stop dropping it, please?')
                }
            }
            if (clicked && !dropped) {
                // defines acceptable target for bone
                if (bonePosition.x >= 100 && bonePosition.x <=300 && bonePosition.y >= 300 && bonePosition.y <= 450) {
                    animate(
                        ".treat",
                        { opacity: 0 },
                        { duration: 0.5}
                    )
                    successMessage();
                } else {
                    animate(
                        ".treat",
                        {y: [mousePosition, 600]},
                        { type: "spring"},
                        {duration: 2}
                    )
                    setDropped(true)
                    console.log("dropped")
                    setLayla(madlayla)
                    setMessage('you silly goose you dropped it.')
                }
            } else if (!clicked && !dropped) {
                setMessage('what are you doing? Gve it to me!')
            }
        // if it's not within the target, it shuold drop to the ground
            // x-index stays the same and y-index becomes 0
        // if it's in the target, you get happy layla and you get the option to restart
        } else if (window.screen.width <= 425 && window.screen.width > 375) {
            console.log("screen size is 425")
            if (clicked && !dropped) {
                // defines acceptable target for bone
                console.log("clicked and not dropped")
                if (bonePosition.x >= 90 && bonePosition.x <=120 && bonePosition.y >= 450 && bonePosition.y <= 550) {
                    animate(
                        ".treat",
                        { opacity: 0 },
                        { duration: 0.5}
                    )
                    successMessage();
                } else {
                    animate(
                        ".treat",
                        {y: [mousePosition, 600]},
                        { type: "spring"},
                        {duration: 2}
                    )
                    setDropped(true)
                    console.log("dropped")
                    setLayla(madlayla)
                    setMessage('you silly goose you dropped it.')
                }
            } else if (dropped) {
                console.log("clicked and dropped")
                if (bonePosition.x >= 220 && bonePosition.x <=320 && bonePosition.y >= 530 && bonePosition.y <= 630) {
                    console.log("in target")
                    animate(
                        ".treat",
                        { opacity: 0 },
                        { duration: 0.5}
                    )
                    successMessage();
                } else {
                    animate(
                        ".treat",
                        {y: [mousePosition, 600]},
                        { type: "spring"},
                        {duration: 2}
                    )
                    setMessage('Can you stop dropping it, please?')
                }
            }
        } else if (window.screen.width <= 375) {
            console.log("screen size is 375")
            if (clicked && !dropped) {
                // defines acceptable target for bone
                console.log("clicked and not dropped")
                if (bonePosition.x >= 90 && bonePosition.x <=120 && bonePosition.y >= 450 && bonePosition.y <= 550) {
                    animate(
                        ".treat",
                        { opacity: 0 },
                        { duration: 0.5}
                    )
                    successMessage();
                } else {
                    animate(
                        ".treat",
                        {y: [mousePosition, 600]},
                        { type: "spring"},
                        {duration: 2}
                    )
                    setDropped(true)
                    console.log("dropped")
                    setLayla(madlayla)
                    setMessage('you silly goose you dropped it.')
                }
            } else if (dropped) {
                console.log("clicked and dropped")
                if (bonePosition.x >= 200 && bonePosition.x <=300 && bonePosition.y >= 450 && bonePosition.y <= 550) {
                    console.log("in target")
                    animate(
                        ".treat",
                        { opacity: 0 },
                        { duration: 0.5}
                    )
                    successMessage();
                } else {
                    animate(
                        ".treat",
                        {y: [mousePosition, 600]},
                        { type: "spring"},
                        {duration: 2}
                    )
                    setMessage('Can you stop dropping it, please?')
                }
            }
        }
    }

    useEffect(() => {
        console.log("bone position: ", bonePosition)
    }, [bonePosition])





    return(
        <>
        <div id="Layla">
            {/* <Treat
            /> */}
            {!success &&
                <motion.img
                    drag
                    dragConstraints={{
                        top: 0,
                        left: 0,
                        right: 1800,
                        bottom: 600
                    }}
                    whileHover={{scale:1.1}}
                    src={dogtreat}
                    height='100'
                    width='100'
                    alt='dog treat'
                    className="treat"
                    style={{
                        transformOrigin: "left top",
                    }}
                    onMouseEnter={() => {setMessage('yes! That treat! bring it over here!!')}}
                    onClick={handlePickup}
                    onMouseLeave={handleDrop}
                    onPointerLeave={handleDrop}
                    onPointerEnter={handlePickup}
                />
            }
            <div className='layla-container'>
                <img className='sweater-layla' src={layla} alt='layla in her green vest'/>
                <div className="message-container">
                    <div className="layla-message">
                        {message}
                    </div>
                    {success && !saidNo &&
                        <>
                            <div className="buttons">
                                <button
                                    className="yes-btn"
                                    onClick={() => window.location.reload()}
                                >
                                        yes
                                </button>
                                <button
                                    className="yes-btn"
                                    onClick={sayNo}
                                >
                                    no
                                </button>
                            </div>
                        </>
                    }
                </div>
            </div>
            {/* <Link to="/walk">Maze</Link> */}

            {/* <div className="layla-mouth" onDrageEnter={() => successMessage}></div> */}
        </div>
        </>
    )
}

export default LaylaTreat
