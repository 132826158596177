import menlayla from "./images/menlayla.png";
import "./Landing.css";

function MenLayla() {
    return (
        <>
            <div id="MenLayla">
                <img src={menlayla} alt="me and layla" />
            </div>
        </>
    )
}

export default MenLayla;
