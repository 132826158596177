import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Nav from './Nav';
// import AboutMe from './AboutMe';
import LaylaTreat from './LaylaTreat';
import Landing from './Landing';
import ProjectsFull from './ProjectsFull';
import Maze from './Maze';
import ServicesFull from './ServicesFull';

function App() {
  const domain = /https:\/\/[^/]+/;
  const basename = process.env.PUBLIC_URL.replace(domain, '');
  console.log("basename: ", domain)
  return (
    <BrowserRouter basename = {basename}>
      <Nav />
        <Routes>
          <Route path="/" element={<Landing />}/>
          <Route path="/layla" element={<LaylaTreat />} />
          <Route path="/projects" element={<ProjectsFull />} />
          <Route path="/walk" element={<Maze />} />
          <Route path="/services" element={<ServicesFull />}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
