import { HashLink } from 'react-router-hash-link';
import "./Nav.css";
import { useLocation, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Nav() {
    const [showNav, setShowNav] = useState(true);
    const location = useLocation();

    useEffect(() => {
        function getLocation() {
            if (location.pathname !== "/") {
                setShowNav(false)
            } else {
                setShowNav(true)
            }
        };
        getLocation();
    }, [location])

    return (
        <>
            <nav>
                <NavLink to="/" className="logo">AV</NavLink>
                {showNav &&
                    <ul className="nav-bar">
                        <li>
                            <HashLink to="#AboutMe">
                                About me
                            </ HashLink>
                        </li>
                        <li>
                            <HashLink to="#Services">
                                Services
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#Projects2">
                                Projects
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#Contact">
                                Contact
                            </HashLink>
                        </li>

                    </ul>
                }
            </nav>
        </>
    )
}


export default Nav;
