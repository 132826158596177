import layla from "./images/IMG_4407.png";
import { useState } from "react";
import { NavLink } from "react-router-dom";

function LaylaFooter() {
    const [hover, setHover] = useState(false)
    return(
        <div>
            <div id="LaylaFooter">
                {
                hover && (
                    <div className="play">Let's play!</div>
                )}
                <NavLink to="/layla">
                    <img
                        src={layla}
                        alt="layla smiling"
                        className="layla"
                        onMouseEnter={()=>setHover(true)}
                        onMouseLeave={()=>setHover(false)}
                    />
                </NavLink>
            </div>
        </div>
    )
}

export default LaylaFooter;
