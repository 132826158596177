import "./ComingSoon.css";
// import laylaleft from "./images/UN-removebg-preview.png";
// import laylaright from "./images/laylaright.png";
import laylatongue from "./images/laylatongue.png";
import { Link } from "react-router-dom";

function ComingSoon() {
    return(
        <div id="ComingSoon">
            <div className="container">
                COMING SOON...
                <Link to="/">
                    <button className="extra">Go back</button>
                </Link>
            </div>
        {/* <img className="laylaleft" src={laylaleft} alt="Layla's on the left side" /> */}
        <img className="laylatongue" src={laylatongue} alt="Layla's on the right side" />
        </div>
    )
}

export default ComingSoon;
