import "./Projects2.css";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import placeholder from "./images/Screenshot 2024-08-14 at 4.03.25 PM.png";

function Project(props) {
    console.log("props: ", props)
    const dates = props.date;
    const url = props.url;
    const description = props.description;
    const title = props.title;
    const tech = props.tech;
    const [img, setImg] = useState(false)
    const location = useLocation();

    useEffect(() => {
        const getLocation = async() => {
            console.log("location: ", location)
            if (location.pathname === "/projects") {
                setImg(true)
            } else {
                setImg(false)
            }
        };

        getLocation();
    }, [location])

    return(
        <Link to={url} target="_blank">
            <div className="project-block">
                <div className="date-container">
                    {dates}
                </div>
                <div className="info">
                    <div>
                        <h4 className="title">
                            {title}
                        </h4>
                        <div className="description">
                            {description}
                        </div>
                    </div>
                        <div className="techstack">
                            {tech.map((tech)=> {
                                return(
                                    <div className="tech">{tech}</div>
                                )

                            })}
                        </div>
                </div>
                {/* add  images here after adding the images to the list */}
                {img &&
                    <img
                        src={placeholder}
                        className="project-img"
                        alt="placeholder"
                    />
                }
            </div>
        </Link>
    )
}

export default Project;
