import "./Landing.css";
import MenLayla from "./MenLayla";
import AboutMe from "./AboutMe";
import Projects2 from "./Projects2";
import Contact from "./Contact";
import Services from "./Services";
// import Layla from "./Layla";
import LaylaFooter from "./LaylaFooter";



function Landing() {
    return(
        <>
        <div id = "Landing">
            <div className="left-container">
                <h1>ANGELIKA VILLAPANDO</h1>
                <div className="roles">
                    FULL STACK SOFTWARE ENGINEER &&
                    <br></br>
                    WEB DEVELOPER
                </div>
            </div>
            <div className="right-container">
                <MenLayla />
                <AboutMe />
                <Services />
                <Projects2 />
                <Contact />
                <LaylaFooter />
            </div>
        </div>
        </>
    )
}
export default Landing
