import ComingSoon from "./ComingSoon";

function ServicesFull() {
    return(
        <div id="ServicesFull">
            {/* Services Full */}

            <ComingSoon />
        </div>
    )
}

export default ServicesFull;
