import "./AboutMe.css";
// import { FaArrowRightLong } from "react-icons/fa6";



function AboutMe() {
    return(
        <>
        <div id="AboutMe">
            <h4>About me</h4>
            <div>
                I'm a passionate Full Stack Software Engineer with a strong foundation in both front-end and back-end development.  I leverage my skills in languages like React, Node.js (FastAPI), Python, and JavaScript to build user-friendly and efficient applications.

                <br></br>
                <br></br>

                My experience ranges from building full-stack platforms like OddJobs (a service platform connecting task providers and helpers) to developing user interfaces and functionalities for websites like PopcornPicks (a movie review platform).  I'm also comfortable working with clients, as demonstrated by my freelance experience where I used Strapi, a headless CMS, to empower a client with independent content management.
            </div>
            <button className="extra"> Learn more </button>
        </div>
        </>
    )
}
export default AboutMe
